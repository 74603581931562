<template>
  <div class="filters-list" data-t="filters-list">
    <div class="filters-select">
      <select v-model="activeFilter" data-t="select">
        <option v-for="filter in filters" :key="filter.id" :value="filter.id">
          {{ filter.title }}
        </option>
      </select>
      <StIcon class="select-chevron" name="chevron-down" :size="16" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MobileMarketsFilterType } from '../../types'

interface Props {
  modelValue: MobileMarketsFilterType
}
const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: MobileMarketsFilterType): void
}>()

const activeFilter = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

const { t } = useI18n()
const filters = computed(() => [
  {
    id: 'exodus',
    title: t('markets.exodus'),
  },
  {
    id: 'handicap',
    title: t('markets.handicap'),
  },
  {
    id: 'total',
    title: t('markets.total'),
  },
])
</script>

<style scoped>
/* stylelint-disable */
.filters-select {
  position: relative;

  display: flex;
  align-items: center;
  min-width: 75px;
  height: 32px;
  border-radius: var(--border-radius-100);
  background: var(--background-secondary);

  select {
    appearance: none;

    display: inline-flex;
    width: 100%;
    padding: var(--spacing-100);
    padding-right: var(--spacing-400);

    border: none;
    background: none;

    font: var(--desktop-text-xs-medium);
    color: var(--text-primary);
    outline: none;

    option {
      background-color: var(--background-secondary);
    }
  }
}
/* stylelint-enable */
.select-chevron {
  pointer-events: none;
  position: absolute;
  right: 8px;
  color: var(--text-tertiary);
}
</style>
