<template>
  <div
    class="tournament-panel"
    :class="{ mini: !localVisible }"
    data-t="tournament-panel"
    @click="toogleElement()"
  >
    <header class="tournament-header" data-t="title-category-tournament">
      <div class="tournament-header-text">
        <p data-t="title-category">{{ category?.name }}</p>
        <p>{{ ' | ' }}</p>
        <p data-t="title-tournament">{{ tournament.name }}</p>
      </div>
      <span
        v-if="props.counter"
        class="tournament-count"
        data-t="tournament-counter"
      >
        {{ props.counter }}
      </span>
      <div class="tournament-collapse">
        <StIcon
          data-t="tournament-icon-collapse"
          :name="collapseIconName"
          :size="14"
        />
      </div>
    </header>
  </div>
</template>

<script lang="ts" setup>
import type { CustomMenuItem } from 'markets-store/types'

interface Props {
  tournament: CustomMenuItem
  category: CustomMenuItem
  counter: number
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'toogleTournament'): void
}>()

const localVisible = ref(true)
const collapseIconName = computed(() =>
  localVisible.value ? 'chevron-top' : 'chevron-down',
)

function toogleElement() {
  emit('toogleTournament')
  localVisible.value = !localVisible.value
}
</script>

<style scoped>
.tournament-header {
  overflow: hidden;
  display: flex;
  align-items: center;

  width: 100%;
  padding: var(--spacing-075) var(--spacing-050) var(--spacing-075)
    var(--spacing-150);

  background-color: var(--sport-group-background, var(--background-primary));
  border-radius: var(--border-radius-150) var(--border-radius-150) 0 0;

  p {
    overflow: hidden;

    margin: 0;

    font: var(--mobile-text-regular);
    color: var(--text-secondary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tournament-panel {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--background-primary);
  border-bottom: var(--border-width-light) solid var(--border-primary);
  border-radius: var(--border-radius-150) var(--border-radius-150) 0 0;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;

    height: 10px;

    background-color: var(--background-base);
  }

  &.mini {
    border: none;
    border-radius: var(--border-radius-150);

    .tournament-header {
      border-radius: var(--border-radius-150);
    }
  }
}

.tournament-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  margin-right: var(--spacing-100);
}

.tournament-collapse {
  display: flex;
  align-items: center;
  padding: var(--spacing-075);
  color: var(--text-tertiary);
}

.tournament-count {
  padding-left: var(--spacing-075);
  font: var(--mobile-caption-1-medium);
  color: var(--text-primary);
  text-align: right;
}

.tournament-header-text {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin-right: auto;

  white-space: nowrap;
}
</style>
